<template>
  <div class="tenant-select">
    <v-autocomplete
      :items="getAll"
      v-model="selectedTenent"
      :class="attention"
      :disabled="getStickyTenantDisabled"
      label="Select Tenant"
      item-value="code"
      item-text="name"
    />

    <BaseConfirmModal
      :value="showUnsaved"
      title='Unsaved Changes'
      text='You have made changes to this page that are not saved. Continue and discard changes?'
      declineText="Cancel"
      @clicked="unsavedModalConfirm"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TheTenantSelect',
  components: {
    BaseConfirmModal: () => import('@/components/base/BaseConfirmModal'),
  },
  data: () => ({
    showUnsaved: false,
    tempTenantCode: '',
  }),
  computed: {
    ...mapGetters('app', ['getStickyTenant', 'getStickyTenantDisabled', 'getUnsaved']),
    ...mapGetters('tenants', ['getAll']),
    selectedTenent: {
      set(tenantCode) {
        if (this.getUnsaved) {
          this.showUnsaved = true;
          this.tempTenantCode = tenantCode;
        } else {
          this.setStickyTenant(tenantCode);
        }
        /* if (tenantCode) {
          this.setStickyTenant(tenantCode);
        } */
      },
      get() {
        return this.getStickyTenant;
      },
    },
    attention() {
      return !this.getStickyTenant ? 'attention' : '';
    },
  },
  methods: {
    ...mapActions('app', [
      'setStickyTenant',
      'setUnsaved',
    ]),
    unsavedModalConfirm(confirm) {
      if (confirm) {
        this.setStickyTenant(this.tempTenantCode);
        this.setUnsaved(false);
      }
      this.showUnsaved = false;
    },
  },
};
</script>
